// extracted by mini-css-extract-plugin
export var caseContactFormSection = "by_k4";
export var caseStudyBackground__lineColor = "by_kW";
export var caseStudyHead = "by_k6";
export var caseStudyHead__imageWrapper = "by_kT";
export var caseStudyProjectsSection = "by_k5";
export var caseStudyQuote__bgRing = "by_k1";
export var caseStudyQuote__bottomVideo = "by_lZ";
export var caseStudyVideoReview = "by_l1";
export var caseStudyVideoReview__bgRing = "by_l2";
export var caseStudyVideo__ring = "by_k8";
export var caseStudy__bgDark = "by_kS";
export var caseStudy__bgLight = "by_kR";
export var caseStudy__bgLightReverse = "by_l0";